import React          from 'react';
import { graphql }    from 'gatsby';
import Img            from 'gatsby-image';
import styled         from '@emotion/styled';
import { RiTeamFill } from 'react-icons/ri';

import HeaderImage  from '@interness/web-core/src/components/media/HeaderImage/HeaderImage';
import Heading      from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer       from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper      from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import CallToAction from '@interness/theme-sonora/src/components/CallToAction/CallToAction';


const SDisplay = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const SDisplayItem = styled.div`
    width: ${props => props.width};
    margin-bottom: 50px;
    position: relative;
    @media (max-width: 640px) {
        width: 100%;
    }

    > div {
        margin-bottom: 20px;
        position: relative;
        border: 5px solid ${props => props.theme.colorMode === 'dark' ? 'rgba(255,255,255,0.8)' : 'rgba(0,0,0,0.7)'} !important;
    }

    h4 {
        background-color: ${props => props.theme.colorMode === 'dark' ? 'rgba(255,255,255,0.8)' : 'rgba(0,0,0,0.7)'} !important;
        color: ${props => props.theme.colorMode === 'dark' ? '#333' : '#fff'} !important;
        text-transform: uppercase;
        padding: 10px 0;
        margin: auto;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
    }

    h5 {
        font-size: 1.2rem;
        font-weight: 300;
        text-align: left;
        margin-top: 10px;
    }

    p {
        text-align: justify;
        font-size: 0.9rem;
    }

    a {
        position: absolute;
        bottom: -42px;
    }
`;

function AboutPage(props) {
  return (
    <>
      <HeaderImage>
        <Img fluid={props.data.headerImage.childImageSharp.fluid}/>
      </HeaderImage>
      <Wrapper>
        <Spacer/>
        <Heading subtitle={'Juwelier Deutsch'} icon={<RiTeamFill/>}>Team</Heading>
        <Spacer/>
        <SDisplay>
          {props.data.team.nodes.map(item => (
            <SDisplayItem width={'45%'} key={item.name}>
              <div>
                <Img fluid={item.portrait.localFile.childImageSharp.fluid} alt={item.name}/>
                <h4>{item.name}</h4>
              </div>
            </SDisplayItem>
          ))}
        </SDisplay>
        <Spacer/>
        <CallToAction/>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default AboutPage;

export const query = graphql`
    query AboutPageQuery {
        headerImage: file(relativePath: {eq: "headers/about.png"}) {
            name
            childImageSharp {
                fluid(maxWidth: 1980, maxHeight: 660, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        team: allDirectusTeamMembers(filter: {id: {ne: "dummy"}}) {
            nodes {
                name
                portrait {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 530, quality: 95) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                translations {
                    language
                    description
                }
            }
        }
    }
`;